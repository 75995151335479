import styled from '@emotion/styled';
import { useCrossDomainLink, useTranslationWithAttributes } from 'hooks';
import { LINKS } from '../../constants/constants';
import Link from 'next/link';
import { LogoNewFull } from '../ui/LogoFullNew';

const Container = styled.footer({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: '30px',
  height: '133px',
});

const Copyright = styled.p({
  fontSize: '16px',
  lineHeight: '18.96px',
  fontWeight: 400,
  textAlign: 'center',
  color: '#3F3E3E',
});

export const Footer = () => {
  const [copyrightTranslation, copyrightAttrs] = useTranslationWithAttributes('qr.landing.footer.right');

  return (
    <Container>
      <Link href={useCrossDomainLink('main', LINKS.ROOT)}>
        <picture>
          <LogoNewFull />
        </picture>
      </Link>
      <Copyright {...copyrightAttrs}>{copyrightTranslation}</Copyright>
    </Container>
  );
};
