import styled from '@emotion/styled';
import NextLink from 'next/link';
import { DisabledCodeIcon } from './DisabledCodeIcon';
import { useCrossDomainLink, useTranslationWithAttributes } from 'hooks';
import { Footer } from './Footer';
import { Trans } from 'react-i18next';
import { LINKS } from '../../constants/constants';

// TODO: replace with token when migrating to Panda CSS
const DESKTOP_MEDIA_QUERY = '@media (min-width: 1220px)';

const Container = styled.div({
  display: 'flex',
  flexFlow: 'column',
  placeContent: 'center',
  alignItems: 'center',
  minHeight: '100dvh',
});

const ContentContainer = styled.div({
  flexGrow: '1',
  display: 'flex',
  flexFlow: 'column',
  placeContent: 'center',
  alignItems: 'center',
  gap: '30px',
  padding: '0 30px',
});

const TextContainer = styled.div({
  display: 'flex',
  flexFlow: 'column',
  gap: '10px',
  textAlign: 'center',
  maxWidth: '330px',
  [DESKTOP_MEDIA_QUERY]: {
    maxWidth: '400px',
  },
});

const Title = styled.h1({
  fontWeight: '600',
  fontSize: '20px',
  lineHeight: '23.7px',
  // TODO: replace with token when migrating to Panda CSS
  color: '#0A0909',
  [DESKTOP_MEDIA_QUERY]: {
    fontSize: '25px',
    lineHeight: '29.63px',
  },
});

const Description = styled.p({
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '25px',
  // TODO: replace with token when migrating to Panda CSS
  color: '#3F3E3E',
  [DESKTOP_MEDIA_QUERY]: {
    fontSize: '18px',
    lineHeight: '28px',
  },
  padding: '0 40px',
});

const Link = styled(NextLink)({
  textDecoration: 'none',
  // TODO: replace with token when migrating to Panda CSS
  color: '#3D75F3',
});

export const DisabledCodeView = () => {
  const [titleTranslation, titleAttrs] = useTranslationWithAttributes('qr.codes.not.found.title');
  const [descriptionTranslation, descriptionAttrs] = useTranslationWithAttributes('qr.codes.not.found.description_v2');

  return (
    <Container>
      <ContentContainer>
        <DisabledCodeIcon />
        <TextContainer>
          <Title {...titleAttrs}>{titleTranslation}</Title>
          <Description {...descriptionAttrs}>
            <Trans
              i18nKey={descriptionTranslation}
              components={{
                Link: <Link href={useCrossDomainLink('main', LINKS.LOGIN)} />,
              }}
            />
          </Description>
        </TextContainer>
      </ContentContainer>
      <Footer />
    </Container>
  );
};
