import { SVGProps } from 'react';

export const DisabledCodeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={120} height={108} viewBox="0 0 120 108" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M79.2 3.625H16.8c-9.278 0-16.8 7.522-16.8 16.8v62.4c0 9.278 7.522 16.8 16.8 16.8h62.4c9.278 0 16.8-7.522 16.8-16.8v-62.4c0-9.278-7.522-16.8-16.8-16.8"
      fill="#F5F6F7"
    />
    <path
      d="M40.804 49.175h-15.6a3.6 3.6 0 0 1-3.6-3.6v-15.6a3.6 3.6 0 0 1 3.6-3.6h15.6a3.6 3.6 0 0 1 3.6 3.6v15.6a3.6 3.6 0 0 1-3.6 3.6m-15.6-19.2v15.6h15.6v-15.6z"
      fill="#20201D"
    />
    <path d="M36 34.781h-6v6h6zm28.805 0h-6v6h6zM36 63.625h-6v6h6z" fill="#20201D" />
    <path
      d="M69.598 49.175h-15.6a3.6 3.6 0 0 1-3.6-3.6v-15.6a3.6 3.6 0 0 1 3.6-3.6h15.6a3.6 3.6 0 0 1 3.6 3.6v15.6a3.6 3.6 0 0 1-3.6 3.6m-15.6-19.2v15.6h15.6v-15.6zM40.804 78.019h-15.6a3.6 3.6 0 0 1-3.6-3.6v-15.6a3.6 3.6 0 0 1 3.6-3.6h15.6a3.6 3.6 0 0 1 3.6 3.6v15.6a3.6 3.6 0 0 1-3.6 3.6m-15.6-19.2v15.6h15.6v-15.6zm32.395-2.444h-4.8v4.8h4.8zm4.801 4.859h-4.8v4.8h4.8zm-4.801 4.782h-4.8v4.8h4.8zm9.601-9.641h-4.8v4.8h4.8zm4.801 4.859h-4.8v4.8h4.8zm-3.605 10.797h-4.8v4.8h4.8z"
      fill="#20201D"
    />
    <path
      d="M94.797 64.781a21.6 21.6 0 0 1 19.956 13.334 21.596 21.596 0 0 1-28.222 28.222 21.598 21.598 0 0 1-7.007-35.23 21.6 21.6 0 0 1 15.273-6.326"
      fill="#fff"
    />
    <path d="M94.798 103.163a16.801 16.801 0 1 1 16.8-16.8 16.82 16.82 0 0 1-16.8 16.8" fill="#F86666" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.587 79.643a1.2 1.2 0 0 1 0 1.697l-11.88 11.88a1.2 1.2 0 1 1-1.696-1.698l11.879-11.88a1.2 1.2 0 0 1 1.697 0"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.01 79.643a1.2 1.2 0 0 1 1.698 0l11.879 11.879a1.2 1.2 0 1 1-1.697 1.697l-11.88-11.88a1.2 1.2 0 0 1 0-1.697"
      fill="#fff"
    />
  </svg>
);
